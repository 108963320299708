import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import styled from "@emotion/styled";

const ThemeToggle = styled(ThemeToggler)`
width:100%;
display:flex;
justify-content:center;
align-items:center;
:hover{
.cls-4{fill:var(--lightStripesHover);}

}
`
const ToggleD = styled.div`
padding-bottom:7px;

width:100%;
display:flex;
justify-content:center;
align-items:center;
cursor:pointer;
:hover{
.cls-4{fill:var(--lightStripesHover);}

}
`

const StyledToggle = styled.div`

	width: 1.9em;
cursor:pointer;
.cls-1{fill:#dac1dd;opacity:0.29;}
.cls-2{fill:var(--lamp);}
.cls-3{fill:var(--light);}
.cls-4{

fill:var(--lightStripes);
transition:all ease .4s;
}
.cls-5{fill:var(--lampLine);}

:hover{
.cls-4{fill:var(--lightStripesHover);}

}

:before,
:after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
:root {
	font-size: calc(64px + (80 - 64) * (100vw - 320px) / (960 - 320));
}

body {
	background: #bbb;
}
input {
	display: block;
	transform:scale(1.5,1.5);
	margin-bottom: 1.5em;
}
main {
	padding: 1.5em 0 0 0;
	text-align: center;
}
.l {
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 0.75em;
	box-shadow: 0.07em 0.07em 0 0.07em rgba(0, 0, 0, 0.05) inset;
	color: #fdea7b;
	display: inline-flex;
	align-items: center;
	margin: auto;
	padding: 0.15em;
	width: 3em;
	height: 1.5em;

	transition: background-color 0.1s 0.3s ease-out, box-shadow 0.1s 0.3s ease-out;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.l:before,
.l:after {
	content: "";
	display: block;
}

.l:before {
	background-color: #d7d7d7;
	border-radius: 50%;
	width: 1.2em;
	height: 1.2em;
	transition: background-color 0.1s 0.3s ease-out, transform 0.3s ease-out;
	z-index: 1;
}

.l:after {
	background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.15) 0) 0 50% / 50%
			100%,
		repeating-linear-gradient(90deg, #bbb 0, #bbb, #bbb 20%, #999 20%, #999 40%) 0
			50% / 50% 100%,
		radial-gradient(circle at 50% 50%, #888 25%, transparent 26%);
	background-repeat: no-repeat;
	border: 0.25em solid transparent;
	border-left: 0.4em solid #d8d8d8;
	border-right: 0 solid transparent;
	transition: border-left-color 0.1s 0.3s ease-out, transform 0.3s ease-out;
	transform: translateX(-22.5%);
	transform-origin: 25% 50%;
	width: 1.2em;
	height: 1em;
}
.l:checked {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0.07em 0.07em 0 0.07em rgba(0, 0, 0, 0.05) inset;
}
.l:checked:before {
	background-color: currentColor;
	transform: translateX(125%);
}
.l:checked:after {
	border-left-color: currentColor;
	transform: translateX(-2.5%) rotateY(180deg);
}
.l:focus {
	outline: 0;
}

`

class Toggler extends React.Component {
  state: { darkMode: boolean; };

 constructor(props) {
   super(props)
   this.state = {
     darkMode: false

   }
 }

 componentDidMount(){
   window.localStorage.setItem('theme', 'light');

   let theme = window.localStorage.getItem('theme');
   if(theme===undefined || theme === 'undefined'){
     window.localStorage.setItem('theme', 'light');
     this.setState({
       darkMode:false
     })
   }
 }

 toggle(toggleTheme){
   if(this.state.darkMode){
     toggleTheme('light')
   }
   else {
     toggleTheme('dark')

   }

   this.setState({
     darkMode:!this.state.darkMode
   })
 }
  render() {
    return (

      <ThemeToggle>

        {({ theme, toggleTheme }) => (
          <ToggleD onClick={() => this.toggle(toggleTheme)}>
          <StyledToggle onClick={() => this.toggle(toggleTheme)}>
            <svg id="lamp" data-name="lamp" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 336.95 492">
              <defs>
                <style></style>
              </defs>
              <title>hang-lamp</title>
              <path className="cls-1"
                    d="M271,198q-7.41-.72-15-.72c-84.27,0-153.2,67-156.36,150.47h30c3-78.5,64.05-142.37,141.36-149.75Z"
                    transform="translate(-87.52 -10)"/>
              <path className="cls-2"
                    d="M254.48,417a53.13,53.13,0,0,0,53.07-53.07v-9.11a6,6,0,0,0-12,0v9.11a41.07,41.07,0,0,1-82.14,0h0v-9.11a6,6,0,0,0-12,0v9.11A53.15,53.15,0,0,0,254.48,417Z"
                    transform="translate(-87.52 -10)"/>
              <path className="cls-4" d="M250,455.51V496a6,6,0,0,0,12,0V455.51a6,6,0,0,0-12,0Z"
                    transform="translate(-87.52 -10)"/>
              <path className="cls-4"
                    d="M303.35,440l28.34,28.85a6,6,0,1,0,8.56-8.41h0l-28.34-28.85a6,6,0,0,0-8.56,8.41Z"
                    transform="translate(-87.52 -10)"/>
              <path className="cls-4"
                    d="M176,470.62a6,6,0,0,0,4.28-1.8L208.65,440a6,6,0,1,0-8.56-8.41h0l-28.34,28.85a6,6,0,0,0,4.28,10.2Z"
                    transform="translate(-87.52 -10)"/>
              <path className="cls-3"
                    d="M230.16,198.81a6,6,0,0,0,6-6V175.05h39.67v17.76a6,6,0,0,0,12,0V168A31.88,31.88,0,0,0,262,136.75V16a6,6,0,0,0-12,0V136.75A31.88,31.88,0,0,0,224.17,168v24.8a6,6,0,0,0,6,6h0ZM256,148.18a19.84,19.84,0,0,1,19.18,14.87H236.82A19.84,19.84,0,0,1,256,148.18Z"
                    transform="translate(-87.52 -10)"/>
              <path className="cls-3"
                    d="M93.53,359.77h325a6,6,0,0,0,6-6c0-92.89-75.58-168.47-168.48-168.47S87.52,260.88,87.52,353.77A6,6,0,0,0,93.53,359.77ZM256,197.3c84.27,0,153.2,67,156.36,150.47H99.64C102.8,264.26,171.73,197.3,256,197.3Z"
                    transform="translate(-87.52 -10)"/>
              <path className="cls-5"
                    d="M186.75,236.81A168.83,168.83,0,0,0,140.82,285,6,6,0,0,0,151,291.37a157,157,0,0,1,42.68-44.74,6,6,0,0,0-6.89-9.82Z"
                    transform="translate(-87.52 -10)"/>
            </svg>
          </StyledToggle>
          </ToggleD>
          )}

      </ThemeToggle>
    )
  }
}

export default Toggler


//         onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
