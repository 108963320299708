import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { SocialIcon } from 'react-social-icons';
import moment from "moment";

import {Location, navigate} from "@reach/router";
import Toggler from "./Toggler";


const StyledFooter = styled.footer`


text-align:center;
 align-items: center;
  margin-left:auto;
  margin-right:auto;
  justify-content:center;
  width:80%;
  display:flex;
  margin-top:40px;
  margin-bottom:40px;
  height:120px;
  
         @media screen and (max-width: 1250px) {
}  
  

`

const FooterBox = styled.div`
 width:auto;
 text-align:left;
  height:120px;
 margin-right:4%;
 padding-right:4%;
 border-right:solid 1px rgba(68, 68, 68, .1);
padding-top:8px;

  @media screen and (max-width: 1250px) {
display:none;
   }

`


const LightDark = styled.div`
 width:auto;
  height:120px;
display:flex;
justify-content:center;
align-items:center;
 border-right:solid 1px rgba(68, 68, 68, .1);
 padding-right:4%;
 padding-top:7px;


  @media screen and (max-width: 1250px) {
display:none;
   }

`
const FooterTxtBox = styled.div`
 width:auto;
 text-align:left;
  height:120px;
  padding-left:4%;
 margin-right:4%;
 padding-right:4%;
 border-right:solid 1px rgba(68, 68, 68, .1);
padding-top:8px;
      color: var(--textLink);

  @media screen and (max-width: 1250px) {
display:none;
   }

`

const SocialBox = styled.div`
  display: flex;
  height:120px;
align-items:center;
   border-right:solid 1px rgba(68, 68, 68, .1);
    padding-right:4%;
    
      @media screen and (max-width: 1250px) {
border:none;   
justify-content:space-between;
width:100%;
    padding-right:0%;
}

`

const A = styled.a`
      color: var(--textLink);

`

function onSelectFlag(countryCode){
  let c = countryCode.toLowerCase();
  console.log(c + ' test c variable');
  if (c === 'de')
    navigate(
      "/",
      { replace: true }      )
  if (c === 'gb')
    navigate('/en',  { replace: true });
}


const FlagDiv = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
width:100px;
padding-left:10px;

`

const StyledLink = styled(Link)`
letter-spacing: 4px;
display:block;
font-size: 0.9em;
width:auto;
margin-top:2px;
      color: var(--textLink);


`


const StyledLink2 = styled.a`
letter-spacing: 4px;
display:block;
font-size: 0.9em;
width:auto;
margin-top:2px;
      color: var(--textLink);


`

const Copyright = styled.div`
  margin-bottom:2%;
  text-align:center;
  letter-spacing: 4px;
  width:100%;
  color:lightgrey;
  margin-left:auto;
  margin-right:auto;
`

const RDiv = styled.div`
width:100% !important;
`

const Invisible = styled.div`
opacity:0;
`









class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isBefore:false
    }
  }


  componentDidMount(){

  }

  render() {
    return(
    <StyledFooter id={'foot'} style={{
      color: 'var(--textNormal)',
      transition: 'color 0.2s ease-out, background 0.2s ease-out',
    }}>
      <FooterBox >

      </FooterBox>
      <FooterBox   style={{
        color: 'var(--textLink)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out',
      }}>
        <StyledLink to="/philosophie">Philosophie</StyledLink>
        <StyledLink to="/services">Services</StyledLink>
        <StyledLink to="/lage">Lage</StyledLink>
        <StyledLink to="/buchung">Buchung</StyledLink>
        <StyledLink to="/kontakt">Kontakt</StyledLink>

      </FooterBox>
      <FooterBox>
        <StyledLink to="/faq">FAQ</StyledLink>

        <StyledLink to="/impressum">Impressum</StyledLink>
        <StyledLink2 href="https://vilhelm7.de/datenschutz">Datenschutz</StyledLink2>
        <StyledLink to="/agb">AGB</StyledLink>

      </FooterBox>
      <SocialBox>
        <SocialIcon style={{height: 55, width: 55}} url="https://www.instagram.com/vilhelm7coworking"
                    fgColor="lightgrey" bgColor="rgba(255,255,255,0.0)"/>
        <SocialIcon style={{height: 55, width: 55}} url="https://www.facebook.com/vilhelm7coworking" fgColor="lightgrey"
                    bgColor="rgba(255,255,255,0.0)"/>
        <SocialIcon style={{height: 55, width: 55}} url="https://twitter.com/v7coworking" network="twitter" fgColor="lightgrey"
                    bgColor="rgba(255,255,255,0.0)"/>
        <SocialIcon style={{height: 55, width: 55}} url="https://wa.me/491732070173" network="whatsapp"
                    fgColor="lightgrey" bgColor="rgba(255,255,255,0.0)"/>

      </SocialBox>
      <FooterTxtBox >
        <p>Wilhelmstraße 7<br/>10963 Berlin Germany</p>
        <p></p>
        <A href={'mailto:info@vilhelm7coworking.de'}>info@vilhelm7coworking.de</A><br/>
        <A href={'tel:+491732070173'}> Tel. +49 173 2070 173</A>
      </FooterTxtBox>

      <LightDark><Toggler/></LightDark>


    </StyledFooter>

    )
  }
}


export default Footer
