import { dimensions, fonts, colors, breakpoints } from './variables'
import { getEmSize } from './mixins'

export default `
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    font-size: ${dimensions.fontSize.regular}px !important;
    line-height: ${dimensions.lineHeight.regular} !important;
  }

  body {
  --bg: white;
    --textNormal:#231f20;
    --textLink:#231f20;
    --textTitle:#231f20;
    
    --navTopBorder:rgba(68, 68, 68, 0.5);
    
    --inputBg:rgba(255,255,255,0.0);
    --inputBgFocused: rgba(233, 231, 175,.2);
    --input:rgb(68,68,68,1);
    --inputFocused:rgb(68,68,68,1);
    --thumbnailBorder:#333;

        --lamp:#231f20;
        --lampLine:#231f20;
--light:#231f20;
--lightStripes:rgba(68,68,68,0);
--lightStripesHover:rgba(68,68,68,0.3);

    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

body.dark {

  --bg: #131217;
  --textNormal:${colors.textDark};
   --textTitle:${colors.textDark};
   --textLink:${colors.textDark};
       --navTopBorder:rgba(255, 255, 255, 0.3);
    --inputBg:rgba(255,255,255,0.1);
    --inputBgFocused: rgba(255,255,255,0.14);
    --input:${colors.textDark};
    --inputFocused:${colors.textDark};
        --thumbnailBorder:#d9d7e0;
        
        --lampLine:#a8eaec;

    --lamp:#e9e7af;
    --light:#a8eaec;
    --lightStripes:#e9e7af;
--lightStripesHover:#e9e7af;

  }
  
  a {
color:var(--textNormal);
    text-decoration: none;
        font-family:${fonts.chapaProLight} !important;
font-size:1.2em;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  
  a.dark{
    --textNormal:${colors.textDark};

  }
  
  

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }

  figure {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 80%;
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid ${colors.ui.light};
    font-size: 85%;
    border-collapse: collapse;
  }

  td,
  th {
    padding: .25rem .5rem;
    border: 1px solid ${colors.ui.light};
  }

  th {
    text-align: left;
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        td {
          background-color: ${colors.ui.whisper};
        }
        tr {
          background-color: ${colors.ui.whisper};
        }
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
color:var(--textNormal);
    margin-top: 1.414rem;
    margin-bottom: .5rem;
  //  color: ${colors.black};
    font-weight: 600;
    line-height: ${dimensions.lineHeight.heading};
    text-rendering: optimizeLegibility;
  }
  
  h1.dark, h2.dark, h3.dark, h4.dark, p.dark, a.dark, div.dark {

  }

  h1 {
    margin-top: 0;
    font-size: 2rem;
    letter-spacing:0.125em;
    line-height:1.2em;
    font-family:${fonts.glypha} !important;
    
     @media screen and (max-width: 480px) {
font-size:1.5rem;   
  }
    
    
  }

  h2 {
    font-size: ${dimensions.headingSizes.h2}rem;
        font-family:${fonts.chapaProSub} !important;

    
  }

  h3 {
    font-size: ${dimensions.headingSizes.h3}rem;
            font-family:${fonts.chapaProSub} !important;


  }

  h4, h5, h6 {
    font-size: ${dimensions.headingSizes.h4}rem;
                font-family:${fonts.chapaProReg} !important;
                font-weight:100;

  }

  p {
color:var(--textNormal) !important;

    margin-top: 0;
    margin-bottom: 1rem;
        font-family:${fonts.chapaProLight} !important;

  }
  
  p.dark{

  }

  strong {
  }
  
  li{
  letter-spacing: 0.1em;

color:var(--textNormal) !important;
      list-style-position: outside;

   font-family: ${fonts.chapaProLight} !important;
list-style-type: square;
  }

  ul,
  ol,
  dl {
  font-family:${fonts.chapaProLight};
    margin-top: 0;
    margin-bottom: 1rem;
    list-style-type: square;
      list-style-position: outside;
font-size: 1.1em;
color:var(--textNormal) !important;

  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-bottom: .5rem;
  }

  hr {
    position: relative;
    margin: 1.5rem 0;
    border: 0;
    border-top: 1px solid ${colors.ui.light};
  }

  blockquote {
  color:var(--textLink);
    margin: .8rem 0;
    padding: .5rem 1rem;
    border-left: .25rem solid ${colors.ui.light};

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: ${getEmSize(breakpoints.md)}em) {
      padding-right: 5rem;
      padding-left: 1.25rem;
    }
  }
`
