import * as React from 'react'
import styled from '@emotion/styled'

import { widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'

const StyledContainer = styled.div`
  width:100%;
  padding-left:13%;
  padding-right:13%;
  

  
   @media screen and (max-width: 870px) {
   
   
   
   
 padding-left:2%;
  padding-right:2%; }
  ul{
  margin-left:18px;
  list-style-position:outside;
  padding:0;
  }  
  li{
  list-style-position:outside;
  }
`

interface ContainerProps {
  className?: string
}

const Container: React.FC<ContainerProps> = ({ children, className }) => <StyledContainer style={{
  backgroundColor: 'var(--bg)',
  color: 'var(--textNormal) !important',
  transition: 'color 0.2s ease-out, background 0.2s ease-out',
}} className={className}>{children}</StyledContainer>

export default Container
