import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
require('../styles/global.css')
import 'modern-normalize'
import '../styles/normalize'
import {BurgerMenu} from "../components/menu/BurgerMenu";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { FiPlus } from 'react-icons/fi';
import { FiPhone } from 'react-icons/fi';
import { FiSend } from 'react-icons/fi';

import Header from '../components/Header'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import styled from "@emotion/styled";
import {navigate} from "@reach/router";
import SliderImg1 from './SliderImg1'
import SliderImg3 from './SliderImg3'
import SliderImg4 from './SliderImg4'
import SliderImg5 from './SliderImg5'
import SliderImg6 from './SliderImg6'
import SliderImg2 from './SliderImg2'
import SliderImg12 from './SliderImg12'
import SliderImg11 from './SliderImg11'
import SliderImg10 from './SliderImg10'
import SliderImg9 from './SliderImg9'
import SliderImg8 from './SliderImg8'


//favIcons



const apple_touch = require('../../assets/images/favicons/apple-icon.png');
const fav32 = require('../../assets/images/favicons/favicon-32x32.png');
const fav16 = require('../../assets/images/favicons/favicon-16x16.png');
//const fav_saf = require('../../assets/images/favicons/safari-pinned-tab.svg');




const StyledCarousel = styled(Carousel)`

width: 80%;
margin-left:auto;
margin-right:auto;
    background:var(--bg);

        @media screen and (max-width: 1200px) {
width:100%;

   }

   .carousel .thumb {
   border:2px solid rgba(255,255,255,0.0);
   }
   .carousel .thumb:focus {
    border: 2px solid #ccc !important;}
  .carousel .thumb .selected{
  border:2px solid #333 !important;
  }

.carousel .slide {
background: rgba(68,68,68,.1) !important;
}

    ul{
    list-style-type: none !important;
}

li{
    list-style-type: none !important;

}


  `

const StyledContainer = styled.div`
z-index:99999;
    background:var(--bg);

.rtf--mb{
font-size:1.5em !important;
}

a{
display:flex;
justify-content:center;
color:rgba(68,68,68,.7);
font-size:1em !important;

}
li{
    list-style-type: none;
}
ul{
    list-style-type: none;
}

.rtf--ab{
background:#ffe799 !important;
color:rgba(68,68,68,.6) !important;
font-size:1em !important;

}
`

const StyledAction = styled(Action)`
button{
background:#ffe799 !important;
color:rgba(68,68,68,.6) !important;



}


`

function handleRequest(){
  navigate('/anfrage')
}

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const IndexLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <div id="outer-container"   style={{
        backgroundColor: 'var(--bg)',
        color: 'var(--textNormal) !important',
        transition: 'color 0.2s ease-out, background 0.2s ease-out',
      }}>
        <BurgerMenu></BurgerMenu>

        <main id="page-wrap">
      <LayoutRoot >

        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords }
          ]}
          link={[
            {
              rel: "apple-touch-icon", sizes: "180x180", href:apple_touch},
            {rel: "icon", type: "image/png", sizes:"32x32", href: apple_touch},
            {rel:"icon",type:"image/png", sizes:"16x16", href: apple_touch },

          ]}
        />


        <Header title={data.site.siteMetadata.title} />
        <Navigation></Navigation>
        <StyledCarousel showStatus={false} className={'cateupel'} transitionTime={1400} dynamicHeight={true} showArrows={true} >
          <div>
<SliderImg1/>
          </div>
          <div>
            <SliderImg2/>
          </div>
          <div>
<SliderImg3/>          </div>
          <div>
            <SliderImg4/>          </div>

          <div>
      <SliderImg5/>          </div>
          <div>
            <SliderImg6/>          </div>

          <div>
            <SliderImg2/>
          </div>
          <div>
            <SliderImg8/>
          </div>
          <div>
            <SliderImg9/>
          </div>
          <div>
            <SliderImg10/>
          </div>
          <div>
            <SliderImg11/>
          </div>
          <div>
            <SliderImg12/>
          </div>
        </StyledCarousel>        <LayoutMain>{children}</LayoutMain>
        <StyledContainer>
          <Fab
            mainButtonStyles={{
              backgroundColor: 'rgb(102, 218, 222)',
              fontSize:'1.2em',
              color:'white'
            }}
            actionButtonStyles={{
              backgroundColor: '#ffe799 !important',
              fontSize:'1.2em',
              color:'rgba(68,68,68,.6)'
            }}
            position={{ bottom: 10, right: 10 }}
            icon={<FiPlus/>}
          >
            <StyledAction
              text="Anrufen"

            >
              <a href={'tel:+491732070173'}>
              <FiPhone/>
              </a>
            </StyledAction>


            <StyledAction
              text="Anfrage senden"
              onClick={handleRequest}
            >
             <FiSend/>
            </StyledAction>

          </Fab>
        </StyledContainer>
        <Footer></Footer>
      </LayoutRoot>
        </main>
      </div>
    )}
  />
)

export default IndexLayout
