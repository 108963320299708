import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import {fonts} from "../styles/variables";
const nav_id = 'nav';



const StyledNavigation = styled.div`
  height:40px;
  width: 80%;
  margin-left:auto;
  margin-right:auto;

  border-top: 2px solid var(--navTopBorder);
  border-bottom: 1px solid rgba(68, 68, 68, 0.3);
  // box-shadow: 0 3px 2px -2px rgba(68,68,68,0.5);
  position:relative;
          z-index: 9999;
  @media screen and (max-width: 1250px) {

display:none;
   }





`

const Dropdown = styled.ul`
  background-color: #fff;
  margin-left:15px;
 text-transform: uppercase;
           opacity:1;
  font-size:0.9em;
margin-top:10px;
    cursor: pointer;
transition: 2s linear;
  text-align:left !important;
        list-style-type: none;
height:150px;
}


`



const NavInner = styled.div`
display: flex;
flex-direction: row;
align-items:center;
justify-content: space-between;
width:90%;
margin:auto;
padding-top:0.5%;
  .popup-content{
   width:210px !important;

   }


`

const StyledLink = styled(Link)`
font-family: ${fonts.chapaProReg};
position:fixed;
letter-spacing: 0.1rem;
font-size:19.4px;
      color: var(--textLink);
position:relative;
z-index:9999;
padding-left:4px;
padding-right:4px;
transition:all .4s ease;


:hover{
text-decoration:none !important;
border-bottom:2px solid rgba(68,68,68,.4);
}
`

const A = styled.a`
font-family: ${fonts.chapaProReg};
position:fixed;
letter-spacing: 0.1rem;
font-size:19.4px;
      color: var(--textLink);
position:relative;
z-index:9999;
padding-left:4px;
padding-right:4px;
transition:all .4s ease;


:hover{
text-decoration:none !important;
border-bottom:2px solid rgba(68,68,68,.4);
}
`

const StyledDropLink = styled(Link)`
font-family: "Theano Didot", Regular;
letter-spacing: 4px;
font-size:1.15em;
color:rgb(68, 68, 68);
position:relative;
z-index:9999;
height:100%;
  display: inline-block;
  :hover ${Dropdown} {
        visibility: visible;
  transition-delay: 0s;
  transition: 2s ease-out;
                }


        :hover{
color:black;
}
`




class Navigation extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      currentTab:0
    }
this.setTab=this.setTab.bind(this)
  }

setTab(num){

    this.setState({
      currentTab:num

    })
}

  render() {
    return (
      <StyledNavigation id={nav_id}>

        <NavInner>
          <StyledLink onClick={() => this.setTab(1)} style={{borderBottom: this.state.currentTab===1 ?'2px solid rgba(68,68,68,.4)':'2px solid rgba(255,255,255,0.0)' }} to="/philosophie">Philosophie</StyledLink>
          <StyledLink onClick={() => this.setTab(2)} style={{borderBottom: this.state.currentTab===2 ?'2px solid rgba(68,68,68,.4)':'2px solid rgba(255,255,255,0.0)' }} to="/services">Services</StyledLink>
          <StyledLink onClick={() => this.setTab(3)} style={{borderBottom: this.state.currentTab===3 ?'2px solid rgba(68,68,68,.4)':'2px solid rgba(255,255,255,0.0)' }} to="/lage">Lage</StyledLink>
          <StyledLink onClick={() => this.setTab(4)} style={{borderBottom: this.state.currentTab===4 ?'2px solid rgba(68,68,68,.4)':'2px solid rgba(255,255,255,0.0)' }} to="/buchung">Buchung</StyledLink>
          <A onClick={() => this.setTab(4)} style={{borderBottom: this.state.currentTab===5 ?'2px solid rgba(68,68,68,.4)':'2px solid rgba(255,255,255,0.0)' }} href="https://www.vilhelm7.de/">Residences</A>
          <StyledLink onClick={() => this.setTab(5)} style={{borderBottom: this.state.currentTab===6 ?'2px solid rgba(68,68,68,.4)':'2px solid rgba(255,255,255,0.0)' }} to="/kontakt">Kontakt</StyledLink>
        </NavInner>
      </StyledNavigation>


  )

  }



}



export default Navigation;
